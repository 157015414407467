import React from 'react';
import GoldIcon from '../assets/img/harmonogram/gold-icon.png'; // Import ikony
import Counter from '../components/Counter';

const MainSection = () => {
  return (
    <section className="content-section bg-light" id="home">
      <div className="bg-green harmonogram-section">
        <div className="text-center harmonogram_text har-main">
          <h1>Budeme se brát!</h1>
          <img className="gold_icon" src={GoldIcon} alt="Gold Icon" />
          <h4 className="harmonogram_date">20.07.2024</h4>
          <p>Zámecká stáj Plandry</p>
        </div>
        <div className="text-center counter_text">
          <h1>Do svatby zbývá</h1>
        </div>
        <Counter />
      </div>
    </section>
  );
};

export default MainSection;
