import React, { useState, useEffect } from 'react';

const formatTimeUnit = (unit) => {
  return unit < 10 ? `0${unit}` : unit;
};

const IndexPage = () => {
  const [countdown, setCountdown] = useState({
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetDate = new Date('2024-07-20T12:00:00');

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const difference = targetDate - now;

      if (difference > 0) {
        const months = Math.floor(difference / (1000 * 60 * 60 * 24 * 30.44));
        const days = Math.floor(
          (difference % (1000 * 60 * 60 * 24 * 30.44)) / (1000 * 60 * 60 * 24)
        );
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setCountdown({ months, days, hours, minutes, seconds });
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container-counter">
      {/* <h1>Čas do svatby</h1> */}
      <div className="row counter-main text-center">
        <div className="col-2 mx-auto counter-text">
          <h1>Měsice</h1>
          <h2>{formatTimeUnit(countdown.months)}</h2>
        </div>
        <div className="col-2 mx-auto counter-text">
          <h1>Dny</h1>
          <h2>{formatTimeUnit(countdown.days)}</h2>
        </div>
        <div className="col-2 mx-auto counter-text">
          <h1>Hodiny</h1>
          <h2>{formatTimeUnit(countdown.hours)}</h2>
        </div>
        <div className="col-2 mx-auto counter-text">
          <h1>Minuty</h1>
          <h2>{formatTimeUnit(countdown.minutes)}</h2>
        </div>
        <div className="col-2 mx-auto counter-text">
          <h1>Sekundy</h1>
          <h2>{formatTimeUnit(countdown.seconds)}</h2>
        </div>
      </div>
      {/* Další obsah */}
    </div>
  );
};

export default IndexPage;
