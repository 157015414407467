import React from 'react';

const GoogleForm = () => {
  return (
    <div className="container" id="form">
      <div className="row">
        <div className="col-md-12">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSdZxWehznPwueQ7GTXEHjmsxFei1X6AkYtZyLgcU9--fflcrw/viewform?embedded=true"
            width="100%"
            height="2500px"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            Načítání…
          </iframe>
        </div>
      </div>
    </div>
  );
};

export default GoogleForm;
