import React from 'react';
import { Carousel } from 'react-bootstrap';
import '../assets/sass/bootstrap/custom-carousel.css'; // Vlastní styly pro Carousel

import img1 from '../assets/images/photos/1.jpg';
import img2 from '../assets/images/photos/2.jpg';
import img3 from '../assets/images/photos/3.jpg';
import img4 from '../assets/images/photos/4.jpg';
import img5 from '../assets/images/photos/5.jpg';
import img6 from '../assets/images/photos/6.jpg';
import img7 from '../assets/images/photos/7.jpg';
import img8 from '../assets/images/photos/8.jpg';
import img9 from '../assets/images/photos/9.jpg';
import img10 from '../assets/images/photos/10.jpg';
import img11 from '../assets/images/photos/11.jpg';
import img12 from '../assets/images/photos/12.jpg';
import img13 from '../assets/images/photos/13.jpg';
import img14 from '../assets/images/photos/14.jpg';
import img15 from '../assets/images/photos/15.jpg';
import img16 from '../assets/images/photos/16.jpg';
import img17 from '../assets/images/photos/17.jpg';
import img18 from '../assets/images/photos/18.jpg';
import img19 from '../assets/images/photos/19.jpg';
import img20 from '../assets/images/photos/20.jpg';
import img21 from '../assets/images/photos/21.jpg';
import img22 from '../assets/images/photos/22.jpg';
import img23 from '../assets/images/photos/23.jpg';
import img24 from '../assets/images/photos/24.jpg';
import img25 from '../assets/images/photos/25.jpg';
import img26 from '../assets/images/photos/26.jpg';
import img27 from '../assets/images/photos/27.jpg';
import img28 from '../assets/images/photos/28.jpg';
import img29 from '../assets/images/photos/29.jpg';
import img30 from '../assets/images/photos/30.jpg';
import img31 from '../assets/images/photos/31.jpg';
import img32 from '../assets/images/photos/32.jpg';
import img33 from '../assets/images/photos/33.jpg';
import img34 from '../assets/images/photos/34.jpg';
import img35 from '../assets/images/photos/35.jpg';
import img36 from '../assets/images/photos/36.jpg';
import img37 from '../assets/images/photos/37.jpg';
import img38 from '../assets/images/photos/38.jpg';
import img39 from '../assets/images/photos/39.jpg';
import img40 from '../assets/images/photos/40.jpg';
import img41 from '../assets/images/photos/41.jpg';
import img42 from '../assets/images/photos/42.jpg';
import img43 from '../assets/images/photos/43.jpg';
import img44 from '../assets/images/photos/44.jpg';
import img45 from '../assets/images/photos/45.jpg';
import img46 from '../assets/images/photos/46.jpg';

const PhotoCarousel = () => {
  return (
    <section className="content-section bg-green carousel-top" id="photos">
      <Carousel id="myCarousel">
        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img1} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Na dovče s kámoši u Zel lam See</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img2} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">
                Vyzvednutí Ádi ve 4 ráno na letišti při příletu z Austrálie
                (čistě kamarádské)
              </h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img3} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">
                Už jako kamarádům nám to spolu slušelo
              </h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img4} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">
                První společná dovča na Mallorce
              </h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img5} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Aďka Šmoula</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img6} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">
                Putování po Česku – Dlouhé Stráně
              </h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img7} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Přerostlé mimino</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img8} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Rakousko na lodičce</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img9} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Společně na hokeji – barvy VŠE</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img10} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Jedna oblíbená ze spolubydlení</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img11} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Instagram</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img12} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Realita</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img13} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Noc v teepee ve Mšenu</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img14} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Schilthorn, Švýcarsko</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img15} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">
                První halloween party – kdo uhádne Ádi kostým získá zlatého
                bludišťáka
              </h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img16} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Na Slapech s našim lalokem</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img17} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">
                Na návštěvě u Dursleyových innit darling
              </h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img18} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">0000007</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img19} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">
                Švýcarské dobrodružství s nejlepšími lidmi
              </h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img20} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Barevná kráska, dvě</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img21} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Když se zrovna máme rádi</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img22} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">
                V covidu jsme byli pěkní jako každý
              </h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img23} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">
                Adélka a Arsenal – ideální kombinace
              </h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img24} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">
                Trocha adrenalinu – rafty Slovinsko
              </h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img25} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Bláznivá jízda na kolobrndách</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img26} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Aaaaaaww </h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img27} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">U Gringotů – já a 4 skřítci</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img28} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Big Ben a big láska</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img29} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">A takhle je to u nás běžně</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img30} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Parta šílenců</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img31} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Na tracku na Corsice</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img32} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">S rodiči v divadle</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img33} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">
                Když jsme se v Brně sladili se svými diplomy
              </h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img34} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Jedno selfie z Teneriffe</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img35} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Výstup na El Teide</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img36} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Druhé selfie z Tenerife</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img37} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">
                Druhá halloween party – tentokrát jsme jasní, nebo?
              </h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img38} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">2 vánoční elfíci u stromečku</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img39} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Vánoce v teple na Madeiře</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img40} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Dva opičáci se perou o banán</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img41} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">
                Na Pico Ruivo za východem slunce
              </h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img42} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Jeden eskymáčí polibek </h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img43} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Jarní lyžování v Alpách</h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img44} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Skejtborďáci </h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img45} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Vzhůru na Sněžník </h3>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-item-container">
            <img className="carousel-img" src={img46} alt="" />
            <div className="carousel-text">
              <h3 className="carousel-name">Jako Rose a Jack</h3>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </section>
  );
};

export default PhotoCarousel;
