import React from 'react';

const InformationSection = () => {
  return (
    <section className="content-section" id="information">
      <div className="container info-top">
        <div className="row no-gutters">
          <div className="col-lg-4">
            <a className="portfolio-item" href="">
              <span className="caption">
                <span className="caption-content text-center">
                  <h1>01</h1>
                  <h2>Ubytování</h2>
                  <p>
                    V omezené kapacitě je pro Vás připraveno ubytování v
                    apartmánech zámecké stáje a dále je možnost přivést si svůj
                    stan a přespat ve stanu či ve spacáku pod širákem.
                  </p>
                </span>
              </span>
            </a>
          </div>
          <div className="col-lg-4">
            <a className="portfolio-item" href="">
              <span className="caption">
                <span className="caption-content text-center">
                  <h1>02</h1>
                  <h2>Doprava</h2>
                  <p>
                    Dopravu k nám si prosím zajistěte. Pro ty, kteří u nás
                    nebudou ubytovaní, bude zajištěna okolo půlnoci doprava
                    minibusem.
                  </p>
                </span>
              </span>
            </a>
          </div>
          <div className="col-lg-4">
            <a className="portfolio-item" href="">
              <span className="caption">
                <span className="caption-content text-center">
                  <h1>03</h1>
                  <h2>Parkování</h2>
                  <p>Před resortem jsou k dispozici parkovací místa.</p>
                </span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InformationSection;
