import React from 'react';
import GoldIcon from '../assets/img/harmonogram/gold-icon.png';
import har1 from '../assets/img/harmonogram/prijezd-hostu.png';
import har2 from '../assets/img/harmonogram/svatebni-obrad.png';
import har3 from '../assets/img/harmonogram/obed.png';
import har4 from '../assets/img/harmonogram/svatebni-foceni.png';
import har5 from '../assets/img/harmonogram/hod-kytici.png';
import har6 from '../assets/img/harmonogram/ziva-hudba.png';

const HarmonograSection = () => {
  return (
    <section className="content-section bg-light" id="harmonogram">
      <div className="gold_line_harmonogram">
        <div className="container">
          <div className="row">
            {/* Sekce 1 */}
            <div className="col-md-6 harmonogram_img har-bottom">
              <img src={har1} alt="Popis obrázku 1"></img>
            </div>
            <div className="col-md-6 text-left text-center-md harmonogram_text">
              <h2>10:00 - 11:00</h2>
              <h3>Příjezd hostů</h3>
            </div>
            {/* Sekce 1 */}
            <div className="col-md-6 text-right text-center-md harmonogram_text">
              <h2>12:00 - 13:00</h2>
              <h3>Svatební obřad</h3>
            </div>
            <div className="col-md-6 harmonogram_img har-bottom">
              <img src={har2} alt="Popis obrázku 2"></img>
            </div>
            {/* Sekce 1 */}
            <div className="col-md-6 harmonogram_img har-bottom">
              <img src={har3} alt="Popis obrázku 3"></img>
            </div>
            <div className="col-md-6 text-left text-center-md harmonogram_text">
              <h2>13:00 - 14:00</h2>
              <h3>Svatební oběd</h3>
            </div>
            {/* Sekce 1 */}
            <div className="col-md-6 text-right text-center-md harmonogram_text">
              <h2>14:00 - 16:00</h2>
              <h3>Společné focení</h3>
            </div>
            <div className="col-md-6 harmonogram_img har-bottom">
              <img src={har4} alt="Popis obrázku 3"></img>
            </div>
            {/* Sekce 1 */}
            <div className="col-md-6 harmonogram_img har-bottom">
              <img src={har5} alt="Popis obrázku 4"></img>
            </div>
            <div className="col-md-6 text-left text-center-md harmonogram_text har-right">
              <h2>16:00 - 18:00</h2>
              <h3>Novomanželské hry, volná zábava</h3>
            </div>
            {/* Sekce 1 */}
            <div className="col-md-6 text-right text-center-md harmonogram_text har-right">
              <h2>18:00 - 22:00</h2>
              <h3>Živá hudba</h3>
            </div>
            <div className="col-md-6 harmonogram_img har-bottom">
              <img src={har6} alt="Popis obrázku 4"></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HarmonograSection;
