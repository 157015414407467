import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';
import GoldIcon from '../assets/img/harmonogram/gold-icon.png';

import config from '../../config';
import Footer from '../components/Footer';
import Header from '../components/Header';
import MainSection from '../components/MainSection';
import Harmonogram from '../components/Harmonogram';
import Photos from '../components/Photos';
import Information from '../components/Information';
import Form from '../components/GoogleForm';
import ScrollToTop from '../components/ScrollToTop';
// import Logo from '../assets/img/logo.png';

const IndexPage = () => (
  <Layout>
    <Header />
    <header className="mainsection">
      <div className="btn-form">
        {/* Použijeme Scroll komponentu pro plynulý přesun */}
        <Scroll type="id" element="form">
          <a href="#form">DOTAZNÍK PRO HOSTY</a>
        </Scroll>
      </div>
      <div className="container text-center my-auto">
        {/* <div className="overlay"></div>
        <img className="logo" src={Logo} alt="Logo" />
        <h1 className="name-logo">
          ADÉLA <br />
          & <br />
          JAKUB
        </h1> */}
      </div>
    </header>
    <MainSection />
    <Harmonogram />
    <Information />
    <Photos />
    <Form />
    <ScrollToTop />
    {/* <Footer /> */}
  </Layout>
);

export default IndexPage;
